import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { AnchorLink } from "gatsby-plugin-anchor-links"

function banner() {
  return (
    <div className="py-140 page-banner ">
      <Container>
        <Row className="flex-wrap-reverse">
          <Col lg={6}>
            <div className=" mt-5">
              <h1 className="page-banner-content">
                HedgePay automatically generates excellent rewards on the
                blockchain
              </h1>
              <p className="mt-5 page-banner-content">
                By utilizing our proprietary HedgeFi risk-reduction algorithm,
                you can customize your BUSD rewards. Simply meet the minimum
                HedgePay balance to generate your individual cryptocurrency BUSD
                rewards. Accessibility, enhanced safety, uniqueness. Earn a
                continuous stream of passive rewards and become independent
                indefinitely.
              </p>
              <div className="d-flex align-items-center justify-content-center justify-content-md-start flex-wrap w-100">
                <div className="me-2">
                  <AnchorLink to="/dex/" className="btn btn-primary">
                    <span>Swap</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="white"
                      className="bi bi-arrow-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                      />
                    </svg>
                  </AnchorLink>
                </div>
              </div>
            </div>
          </Col>

          <Col lg={6}>
            <img
              src="banner-image.svg"
              alt="HedgePay Rewards Geneartor"
              loading="eager"
              className="page-banner-image"
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default banner

import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper";
import { Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import Weechat from '../../images/inline/weechat.inline.svg';
import Telegram from '../../images/inline/telegram-icon.inline.svg';
import Whatsapp from '../../images/inline/whatsapp.inline.svg';
// import CatKey1 from '../../images/jebb.svg';
// import CatKey2 from '../../images/edpardo.svg';
// import CatKey3 from '../../images/crypto-pablo.svg';
// import CatKey4 from '../../images/CatKey4.svg';
// import CatKey5 from '../../images/CatKey5.svg';
// import CatKey6 from '../../images/CatKey6.svg';
// import CatKey7 from '../../images/CatKey7.svg';
// import CatKey8 from '../../images/CatKey8.svg';
// import { Link } from 'gatsby';

function partnership() {
    
    return (
        <div className="py-140 mb-4  text-center ">
            <Container id="about" >
                <Row className="justify-content-center ">

                    <div className="section-title text-center">
                        <div>Our Partners</div>
                        <h3>Our Partners</h3>
                    </div>
                    <Col lg={9} className="px-lg-4 d-none">
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. At, distinctio.
                        </p>
                    </Col>
                </Row>

                <div className="pb-4 pt-3 partner-slider mb-5" >
                    <Swiper
                        className='partner-slider-row-1'
                        loop={true}
                        slidesPerView='auto'
                        spaceBetween={12}
                        freeMode={true}
                        autoplay={{
                            delay: 1000,
                            disableOnInteraction: true,
                        }}
                        speed={3000}
                        modules={[Autoplay]}
                    >
                        {/* style={{backgroundPosition : `${index === 0 ? 0 : (-100 * index) - (index + index) }px 0px`,
                            '&:hover':{
                                backgroundPosition : `${index === 0 ? 0 : (-100 * index) - (index + index) }px -100px`
                            }
                        }} */}
                        {firstRoll?.map((item, index) => (
                            <SwiperSlide key={index}>
                                <a className='partner-logo-box' href={item.link} target={item.target ? '_blank' : '_self'}  rel="noreferrer">
                                    <span className={`plb-logo plb-logo-${index}`} style={{'--pos' : `${index === 0 ? 0 : (-100 * index) - (index + index) }px 0px`, '--hover' : `${index === 0 ? 0 : (-100 * index) - (index + index) }px -102px`}}></span>
                                    <span className="popin-700-12 plb-name">{item.name}</span>
                                </a>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <br />
                    <Swiper
                        className='partner-slider-row-2'
                        loop={true}
                        slidesPerView='auto'
                        spaceBetween={12}
                        freeMode={true}
                        autoplay={{
                            delay: 1000,
                            disableOnInteraction: true,
                            reverseDirection: true,
                        }}
                        speed={3000}
                        modules={[Autoplay]}
                    >
                        {secondRoll?.map((item, index) => (
                            <SwiperSlide key={index}>
                                <a className='partner-logo-box' href={item.link} target={item.target ? '_blank' : '_self'}  rel="noreferrer">
                                    <span className={`plb-logo plb-logo-${index}`} style={{'--pos' : `${index === 0 ? 0 : (-100 * index) - (index + index) }px 0px`, '--hover' : `${index === 0 ? 0 : (-100 * index) - (index + index) }px -102px`}}></span>
                                    <span className="popin-700-12 plb-name">{item.name}</span>
                                </a>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <br />
                    <Swiper
                        className='partner-slider-row-3'
                        loop={true}
                        slidesPerView='auto'
                        spaceBetween={12}
                        freeMode={true}
                        autoplay={{
                            delay: 1000,
                            disableOnInteraction: true
                        }}
                        speed={3000}
                        modules={[Autoplay]}
                    >
                        {thirdRoll?.map((item, index) => (
                            <SwiperSlide key={index}>
                                <a className='partner-logo-box' href={item.link} target={item.target ? '_blank' : '_self'}  rel="noreferrer">
                                    <span className={`plb-logo plb-logo-${index}`} style={{'--pos' : `${index === 0 ? 0 : (-100 * index) - (index + index) }px 0px`, '--hover' : `${index === 0 ? 0 : (-100 * index) - (index + index) }px -102px`}}></span>
                                    <span className="popin-700-12 plb-name">{item.name}</span>
                                </a>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>

                <div className="pt-5">
                    <div className="section-title mt-md-5 text-center">
                        <div>Key Opinion Leaders</div>
                        <h3>Key Opinion Leaders</h3>
                    </div>

                    <Swiper
                        className='partner-key-leader'
                        loop={true}
                        slidesPerView='auto'
                        spaceBetween={12}
                        freeMode={true}
                        autoplay={{
                            delay: 1200,
                            disableOnInteraction: true
                        }}
                        speed={5000}
                        modules={[Autoplay]}
                    >
                        {KeyLeaderRoll?.map((item, index) => (
                            <SwiperSlide key={index}>
                                <a className='partner-logo-box' href={item.link} target={item.target ? '_blank' : '_self'}  rel="noreferrer">
                                    <span className={`plb-logo plb-logo-key-${index}`} style={{'--pos' : `${index === 0 ? 0 : (-100 * index) - (index + index) }px 0px`}}></span>
                                    {/* <span className="plb-name">{item.name}</span> */}
                                </a>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>

                <div className='d-none'>
                    <p className="mb-2">Contact Us</p>
                    <OverlayTrigger
                        overlay={
                            <Tooltip id={`tooltip-Whatsapp`}>
                                <strong>Whatsapp</strong>.
                            </Tooltip>
                        }>
                        <a target="_blank" rel="noopener noreferrer" href="https://chat.whatsapp.com/FfqkTfxSWyy1s2rdX3qrOR">
                            <Whatsapp className="footer-icon" />
                        </a>
                    </OverlayTrigger>

                    <OverlayTrigger
                        overlay={
                            <Tooltip id={`tooltip-Whatsapp`}>
                                <strong>Telegram</strong>.
                            </Tooltip>
                        }>
                        <a target="_blank" rel="noopener noreferrer" href="https://t.me/hedgepay">
                            <Telegram className="footer-icon" />
                        </a>
                    </OverlayTrigger>

                    <OverlayTrigger
                        overlay={
                            <Tooltip id={`tooltip-Whatsapp`}>
                                <strong>Wechat</strong>.
                            </Tooltip>
                        }>
                        <a target="_blank" rel="noopener noreferrer" href="https://msng.link/o/?https%3A%2F%2Fu.wechat.com%2FkKR9RqcilmoXjVzFcyvvsFQ=wc">
                            <Weechat className="footer-icon" />
                        </a>
                    </OverlayTrigger>
                </div>
            </Container>
        </div >
    );
}


export default partnership;

const firstRoll = [
    {name: 'Bitrue', link: 'https://www.bitrue.com/', target:true},
    {name: 'BitMart', link: 'https://www.bitmart.com/', target:true},
    {name: 'LBANK', link: 'https://www.lbank.com/', target:true},
    {name: 'Probit Global', link: 'https://www.probit.com/en-us/', target:true},
    {name: 'BKEX', link: 'https://www.bkex.com/', target:true},
    {name: 'MEXC Global', link: 'https://www.mexc.com/', target:true},
    {name: 'Coin Store', link: 'https://www.coinstore.com/', target:true},
    {name: 'Local Trade', link: 'https://localtrade.cc/', target:true},
    {name: 'DigiFinex', link: 'https://www.digifinex.com/en-ww', target:true},
    {name: 'Transak', link: 'https://hedgepay.org/transak/', target:true},
    {name: 'Grab', link: 'https://www.grab.com/my/pay/', target:true},
    {name: 'Change Now', link: 'https://changenow.io/affiliate', target:true},
    {name: 'Maybank QRPAY', link: 'https://www.maybank2u.com.my/maybank2u/malaysia/en/business/index.page', target:true},
    {name: 'Stripe', link: 'https://dashboard.stripe.com/login?redirect=%2Fdashboard', target:true},
    {name: 'CoinPayments', link: 'https://www.coinpayments.net/login', target:true},
    {name: 'Paynoneer', link: 'https://myaccount.payoneer.com/', target:true},
    {name: 'PayPal', link: 'https://www.paypal.com/signin?returnUri=https%3A%2F%2Fwww.paypal.com%2Fmep%2F', target:true},
    {name: 'Wise', link: 'https://wise.com/login/?redirectUrl=%2Factivity%2F', target:true},
    {name: 'OX Pay', link: 'https://oxpayfinancial.com/', target:true},
    {name: 'Apple Pay', link: 'https://www.apple.com/my/apple-pay/', target:true},
    {name: 'Google Pay', link: 'https://pay.google.com/business/', target:true},
    {name: 'Visa', link: 'https://www.braintreepayments.com/my', target:true},
    {name: 'Master', link: 'https://www.braintreepayments.com/my', target:true},
    {name: 'Wire Transfer', link: 'https://www.maybank2u.com.my/mbb/m2u/common/M2ULogin.do?action=Login', target:true},
    {name: 'Thai QR', link: 'https://www.bangkokbank.com/en/Personal/Other-Services/Payment-and-Top-Up/CrossBorder-QRPayment', target:true},
    {name: 'Maybank', link: 'https://www.maybank2u.com.my/mbb/m2u/common/M2ULogin.do?action=Login', target:true},
    {name: 'Omise', link: 'https://www.omise.co/', target:true},
    {name: 'QRIS', link: 'https://qris.id/homepage/', target:true},
    {name: 'bitpay', link: 'https://bitpay.com/authenticate/login', target:true},
    {name: 'DuitNow', link: 'https://www.touchngo.com.my/duitnow/', target:true},
    // {name: 'OX Pay', link: 'https://www.touchngo.com.my/', target:true},
    {name: 'TnG', link: 'https://www.touchngo.com.my/', target:true},
    {name: 'My Data', link: '', target:true},
    {name: 'Malaysia Data', link: '', target:true},
    {name: 'SSM', link: '', target:true},
    {name: 'LHDN', link: '', target:true},
    {name: 'KWSP', link: '', target:true},
    {name: 'PERKESO', link: '', target:true},
    {name: 'Malaysian Gov', link: '', target:true},
    {name: 'MY IPO', link: '', target:true},
    {name: 'US Trademark', link: '', target:true},
    {name: 'USPTO', link: '', target:true},
    {name: 'FreshCoins', link: 'https://www.freshcoins.io/', target:true},
    {name: 'RugDog', link: 'https://rugdog.net/', target:true},
    {name: 'CyberScope', link: 'https://www.cyberscope.io/', target:true},
    {name: 'CFG Ninja', link: 'https://cfg.ninja/', target:true},
    {name: 'Audit Rate Tech', link: 'https://auditrate.tech/', target:true},
    {name: 'Chainsulting', link: 'https://chainsulting.de/', target:true},
    {name: 'Analytix Audit', link: 'https://analytixaudit.com/', target:true},
    {name: 'RugFreeCoins', link: 'https://www.rugfreecoins.com/', target:true},
    {name: 'HashEx', link: 'https://hashex.org/', target:true},
    {name: 'QuillAudits', link: 'https://audits.quillhash.com/smart-contract-audit', target:true},
    {name: 'Cognitps', link: 'https://cognitos.io/', target:true},
    {name: 'Expelee', link: 'https://expelee.com/', target:true},
    {name: 'BitCourier', link: 'https://bitcourier.co.uk/news/hedgepay-interview', target:true},
    {name: 'ShellBoxes', link: 'https://audit.shellboxes.com/', target:true},
    {name: 'Multichain', link: '', target:true}
]

const secondRoll = [
    {name: 'AptosLaunch', link: 'https://aptoslaunch.io/', target:true},
    {name: 'CoinGecko', link: 'https://www.coingecko.com/en/coins/hedgepay/', target:true},
    {name: 'Blocksultancy', link: 'https://www.blocksultancy.com/', target:true},
    {name: 'Zenshin Tech', link: 'https://zenshin.tech/', target:true},
    {name: 'SIG', link: 'https://www.sersinvestmentgroup.com/', target:true},
    {name: 'The Solitaire', link: 'https://t.me/TheSolitaireRoom', target:true},
    {name: 'WCKD Motions', link: 'https://drive.google.com/file/d/1eFis4HzSq_XyEHSRe2jKEoY6yCunnrFt/view', target:true},
    {name: 'Solidus Ai-Tech', link: 'https://www.ai-tech.io/', target:true},
    {name: 'De.Fi', link: 'https://defiyield.app/', target:true},
    {name: 'PlayGain', link: 'https://playgain.net/', target:true},
    {name: 'Tawk', link: 'https://www.tawk.to/?pid=vb81v1l', target:true},
    {name: 'FiBot', link: 'https://fibot.xyz/', target:true},
    {name: 'CoinBuddy', link: 'https://coinbuddy.co/coins/hedgepay', target:true},
    {name: 'crypto.com', link: 'https://crypto.com/price/hedgepay', target:true},
    {name: 'Nomics', link: 'https://nomics.com/exchanges/pancakeswapv2-pancakeswap-v2/markets/0xa0a1e7571f938cc33dad497849f14a0c98b30fd0', target:true},
    {name: 'ICOHOLDER', link: 'https://icoholder.com/en/hedgepay-1009440', target:true},
    {name: 'Tracxn', link: 'https://tracxn.com/login?redirecturl=%2Fa%2Fcompanies%2FpZShrF2FGjRgT4OKhY1cEhmkbiJEL4TKDld-tizPteA%2Fhedgepay.org', target:true},
    {name: 'CoinMarketCap', link: 'https://coinmarketcap.com/currencies/hedgepay/', target:true},
    {name: 'Google Business', link: 'https://www.google.com/maps/place//data=!4m3!3m2!1s0x31cdcb36d0d4061b:0xef6ba0da65234371!12e1?source=g.page.m.kd._&laa=lu-desktop-review-solicitation', target:true},
    {name: 'DUNS', link: '', target:true},
    {name: 'Binance', link: 'https://www.binance.com/en/price/hedgepay', target:true},
    {name: 'Fiverr', link: 'https://business.fiverr.com/', target:true},
    {name: 'Stocktwits', link: 'https://stocktwits.com/hedgepay', target:true},
    {name: 'Live Coin Watch', link: 'https://www.livecoinwatch.com/price/HedgePay-HPAY', target:true},
    {name: 'Unstoppable', link: 'https://unstoppabledomains.com/resellers', target:true},
    {name: 'CoinCodex', link: 'https://coincodex.com/crypto/hedgepay', target:true},
    {name: 'CoinCheckUp', link: 'https://coincheckup.com/coins/hedgepay', target:true},
    {name: 'Giving Block', link: 'https://thegivingblock.com/', target:true},
    {name: 'Coinbase Wallet', link: 'https://login.coinbase.com/signin?login_challenge=866bc65bd99846138e6de46ba41c0407', target:true},
    {name: 'Dessert Finance', link: '', target:true},
    {name: 'SpyWolf', link: '', target:true},
    {name: 'PinkSale', link: '', target:true},
    {name: 'Travladd', link: '', target:true},
    {name: 'Gollum’s Gem', link: '', target:true},
    {name: 'Imgbb', link: 'https://imgbb.com/', target:true},
    {name: 'Veoh', link: 'https://www.veoh.com/', target:true},
    {name: 'Vingle', link: 'https://www.vingle.net/', target:true},
    {name: 'Visually', link: 'https://visual.ly/', target:true},
    {name: 'Warrior Forum', link: 'https://www.warriorforum.com/', target:true},
    {name: 'Weber Kettle Club', link: 'http://pics.weberkettleclub.com/', target:true},
    {name: 'WebFlow', link: 'https://webflow.io/', target:true},
    {name: 'Weddingbee', link: 'https://www.weddingbee.com/', target:true},
    {name: 'Write a Blog', link: 'https://writeablog.net/', target:true},
    {name: 'Yara Book', link: 'https://yarabook.com/', target:true},
    {name: 'You Magine', link: 'https://www.youmagine.com/', target:true},
    {name: 'YourQuote', link: 'https://www.yourquote.in/', target:true},
    {name: '500px', link: '', target:true},
    {name: 'AACC', link: 'https://www.aacc21stcenturycenter.org/', target:true},
    {name: 'Article USA', link: 'https://articleusa.com/', target:true},
    {name: 'About.me', link: 'https://about.me/', target:true},
    {name: 'ArmMBED', link: 'https://os.mbed.com/', target:true},
    {name: 'Author Stream', link: 'https://www.authorstream.com/', target:true},
    {name: 'Avian Waves', link: 'https://www.avianwaves.com/Blog', target:true},
    {name: 'ASS', link: 'https://articlessubmissionservice.com/', target:true},
    {name: 'Active Pop Sugar', link: 'https://active.popsugar.com/', target:true},
    {name: 'Ange List', link: 'https://angel.co/', target:true},
    {name: 'BlockSpot', link: 'https://blockspot.io/coin/hedgepay/', target:true}
]

const thirdRoll = [
    {name: 'Apaci', link: '', target:true},
    {name: 'Bbpress', link: 'https://bbpress.org/', target:true},
    {name: 'Bcmoney', link: 'http://bcmoney-mobiletv.com/', target:true},
    {name: 'Beacons', link: 'https://beacons.ai/', target:true},
    {name: 'Beat Stars', link: 'https://www.beatstars.com/', target:true},
    {name: 'Big Picture', link: 'https://www.bigpictureclasses.com/', target:true},
    {name: 'Blogger', link: 'https://www.blogger.com/about/?bpli=1', target:true},
    {name: 'Buddy Press', link: 'https://buddypress.org/', target:true},
    {name: 'Chordie', link: 'https://www.chordie.com/', target:true},
    {name: 'Code Chef', link: 'https://www.codechef.com/', target:true},
    {name: 'Conejousd', link: '', target:true},
    {name: 'ConnectGarmin', link: 'https://connect.garmin.com/', target:true},
    {name: 'cosx', link: 'https://d.cosx.org/', target:true},
    {name: 'Coub', link: 'https://coub.com/', target:true},
    {name: 'Dermandar', link: 'https://www.dermandar.com/', target:true},
    {name: 'Descubre', link: 'https://descubre.beqbe.com/', target:true},
    {name: 'Deviantart', link: 'https://www.deviantart.com/', target:true},
    {name: 'Diigo', link: 'https://www.diigo.com/', target:true},
    {name: 'Docker', link: 'https://hub.docker.com/', target:true},
    {name: 'Edex Adobe', link: 'https://edex.adobe.com/', target:true},
    {name: 'Educator Pages', link: '', target:true},
    {name: 'Exchangle', link: 'https://www.exchangle.com/', target:true},
    {name: 'Foldkd.com', link: 'https://folkd.com/', target:true},
    {name: 'Foxsheets', link: 'http://foxsheets.com/', target:true},
    {name: 'Free-Ebooks', link: 'https://www.free-ebooks.net/', target:true},
    {name: 'Gyfcat', link: 'https://gfycat.com/', target:true},
    {name: 'GiantBomb', link: 'https://www.giantbomb.com/', target:true},
    {name: 'Gifyu', link: 'https://gifyu.com/', target:true},
    {name: 'Good Reads', link: 'https://www.goodreads.com/', target:true},
    {name: 'Google Scholar', link: 'https://scholar.google.com/', target:true},
    {name: 'Gravatar', link: 'https://en.gravatar.com/', target:true},
    {name: 'Gta5mods', link: 'https://www.gta5-mods.com/', target:true},
    {name: 'Guides', link: 'https://guides.co/', target:true},
    {name: 'Hearthis', link: 'https://hearthis.at/', target:true},
    {name: 'Hiking Project', link: '', target:true},
    {name: 'Historyillinois', link: 'https://www.historyillinois.org/', target:true},
    {name: 'HuntingNet', link: 'https://www.huntingnet.com/forum/', target:true},
    {name: 'Hypothes', link: 'https://web.hypothes.is/', target:true},
    {name: 'Infogram', link: 'https://infogram.com/', target:true},
    {name: 'Instapaper', link: 'https://www.instapaper.com/', target:true},
    {name: 'Intense Debate', link: 'https://www.intensedebate.com/', target:true},
    {name: 'Issuu', link: '', target:true},
    {name: 'KickStarter', link: 'https://www.kickstarter.com/', target:true},
    {name: 'Life of Pix', link: 'https://www.lifeofpix.com/', target:true},
    {name: 'LiveInternet', link: '', target:true},
    {name: 'Long Island', link: 'https://www.longisland.com/', target:true},
    {name: 'LookBook', link: 'https://lookbook.nu/', target:true},
    {name: 'MeTooo', link: 'https://www.metooo.io/', target:true},
    {name: 'Misterpoll', link: 'https://www.misterpoll.com/', target:true},
    {name: 'MixCloud', link: 'https://www.mixcloud.com/', target:true},
    {name: 'MTB Project', link: 'https://www.mtbproject.com/', target:true},
    {name: 'MyAnimelist', link: 'https://myanimelist.net/', target:true},
    {name: 'NoteFlight', link: 'https://www.noteflight.com/', target:true},
    {name: 'OK-ru', link: 'https://ok.ru/', target:true},
    {name: 'Open Humans', link: 'https://research.openhumans.org/', target:true},
    {name: 'Pantip', link: 'https://pantip.com/', target:true},
    {name: 'Pastebin.com', link: 'https://pastebin.com/', target:true},
    {name: 'PinShape', link: 'https://pinshape.com/', target:true},
    {name: 'PostHaven', link: 'https://posthaven.com/', target:true},
    {name: 'ProvenExpert', link: 'https://www.provenexpert.com/en-us/', target:true},
    {name: 'Qiita', link: 'https://qiita.com/', target:true},
    {name: 'Reverbnation', link: 'https://www.reverbnation.com/', target:true},
    {name: 'Rollbol', link: 'https://rollbol.com/', target:true},
    {name: 'Shellmo', link: 'http://forum1.shellmo.org/', target:true},
    {name: 'Sketchfab', link: 'https://sketchfab.com/', target:true},
    {name: 'Skill Share', link: 'https://www.skillshare.com/', target:true},
    {name: 'SoundClloud', link: 'https://soundcloud.com/', target:true},
    {name: 'StartupMatcher', link: 'https://startupmatcher.com/', target:true},
    {name: 'Subrion CMS', link: 'https://subrion.org/', target:true},
    {name: 'Tapas', link: 'https://tapas.io/', target:true}
]

const KeyLeaderRoll = [
    {name: 'Juani Pacchini', link: 'https://www.instagram.com/juanipacchini/?igshid=YmMyMTA2M2Y%3D', target:true},
    {name: 'Edmundo Gol', link: 'https://www.instagram.com/edmundogol/?igshid=YmMyMTA2M2Y%3D', target:true},
    {name: 'Renzo Tesuri', link: 'https://www.instagram.com/renzotesuri/?igshid=YmMyMTA2M2Y%3D', target:true},
    {name: 'Yuri Mamute', link: 'https://www.instagram.com/mamute09/?igshid=YmMyMTA2M2Y%3D', target:true},
    {name: 'Francisco G.', link: 'https://www.instagram.com/tati_gerometta/?igshid=YmMyMTA2M2Y%3D', target:true},
    {name: 'Imanol Machuca', link: 'https://www.instagram.com/imachucaaa/?igshid=YmMyMTA2M2Y%3D', target:true},
    {name: 'RF7', link: 'https://www.instagram.com/ronan_freitas7/?igshid=YmMyMTA2M2Y%3D', target:true},
    {name: 'Jebb', link: 'https://www.youtube.com/c/CryptoJebb', target:true},
    {name: 'Edoardo', link: 'https://www.tiktok.com/@edoardo_ontano?is_from_webapp=1&sender_device=pc', target:true},
    {name: 'Crypto Pablo', link: 'https://www.youtube.com/channel/UC8wXpVINhSENNMCiXYMAROg', target:true},
    {name: 'Мистер Сайлер', link: 'https://www.youtube.com/channel/UC-EeeS1N2mYNfvk99fZGkEQ', target:true},
    {name: 'Goalorious', link: 'https://www.youtube.com/c/Goalorious', target:true},
    {name: '단감', link: 'https://www.youtube.com/c/%EB%8B%A8%EA%B0%90swpersimmon', target:true},
    {name: 'Abdes. Geek', link: 'https://www.youtube.com/c/AbdessamadGeek05', target:true},
    {name: 'Prof. Crypto', link: 'https://www.youtube.com/c/ProfessorCrypto', target:true}
]
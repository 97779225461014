

import React from "react";
import PropTypes from "prop-types";

import Banner from "./landing/banner";

const Header = () => {
  return (
    <header id="home">
      <Banner />
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
  header: PropTypes.shape({
    mainTitle: PropTypes.string,
    subtitle: PropTypes.string
  })
};

export default Header;

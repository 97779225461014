import React from 'react';
import { Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';

import Weechat from '../../images/inline/weechat.inline.svg';
import Telegram from '../../images/inline/telegram-icon.inline.svg';
import Whatsapp from '../../images/inline/whatsapp.inline.svg';

function about() {
    return (
        <div className="py-140 mb-4 pb-0 text-center ">
            <Container id="about" >
                <Row className="justify-content-center ">

                    <div className="section-title text-center">
                        <div>About Us</div>
                        <h3>About Us</h3>
                    </div>
                    <Col lg={9} className="px-lg-4">
                        <p>
                            HedgePay is the cryptocurrency utilized within the HedgeFi Ecosystem based on improving the resources of HedgeFi Algorithm. Proceeds are used to generate reward that will be delivered to claimable rewards for contributor. HedgePay follows three main tenants called the Three-fold Path.
                        </p>
                    </Col>
                </Row>
                <div className="feature-content mb-0" >
                    <Row >
                        <Col md={4} className="mb-5">
                            <h4> Security </h4>
                            <p>
                            Security is paramount for us. HedgePay utilizes  a proprietary zero-risk liquidity locking mechanism. Backed by Gnosis multi-signature systems, and proprietary modular smart contracts , HedgePay is protected against traditional vulnerabilities.
                            </p>
                        </Col>

                        <Col md={4} className="mb-5">
                            <h4>Rewards Generation </h4>
                            <p>
                                Lifetime earning opportunities is the lifeblood of HedgePay. The HedgeFi Algorithm is structured to utilize the tokens to generate a monthly BUSD rewards that can be used to purchase goods and services. Actualize your returns and improve your daily life.
                            </p>
                        </Col>

                        <Col md={4} className="mb-5">
                            <h4>Accessibility </h4>
                            <p>
                                Accessibility is the major obstacle all cryptocurrencies face when fighting for adoption. HedgePay will bridge the gap between liquidity and tokens while making earn residual rewards a one-step process. By automating passive rewards, the HedgeFi Ecosystem simplifies the journey and focuses on generating usable rewards.
                            </p>
                        </Col>
                    </Row>
                </div>

                <div className='d-none'>
                    <p className="mb-2">Contact Us</p>
                    <OverlayTrigger
                        overlay={
                            <Tooltip id={`tooltip-Whatsapp`}>
                                <strong>Whatsapp</strong>.
                            </Tooltip>
                        }>
                        <a target="_blank" rel="noopener noreferrer" href="https://chat.whatsapp.com/FfqkTfxSWyy1s2rdX3qrOR">
                            <Whatsapp className="footer-icon" />
                        </a>
                    </OverlayTrigger>

                    <OverlayTrigger
                        overlay={
                            <Tooltip id={`tooltip-Whatsapp`}>
                                <strong>Telegram</strong>.
                            </Tooltip>
                        }>
                        <a target="_blank" rel="noopener noreferrer" href="https://t.me/hedgepay">
                            <Telegram className="footer-icon" />
                        </a>
                    </OverlayTrigger>

                    <OverlayTrigger
                        overlay={
                            <Tooltip id={`tooltip-Whatsapp`}>
                                <strong>Wechat</strong>.
                            </Tooltip>
                        }>
                        <a target="_blank" rel="noopener noreferrer" href="https://msng.link/o/?https%3A%2F%2Fu.wechat.com%2FkKR9RqcilmoXjVzFcyvvsFQ=wc">
                            <Weechat className="footer-icon" />
                        </a>
                    </OverlayTrigger>
                </div>
            </Container>
        </div >
    );
}

export default about;
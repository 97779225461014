import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import CountUp from 'react-countup';

import { useInterval } from 'react-use';
import ChainIcon from '../../images/chain-icon.png';
import ExpandIcon from '../../images/expand-icon.png';
import PieChartFee from '../../images/pie-chart-fees.svg';
import PieChart from '../../images/pie-chart.svg';
import TickerSym from '../../images/ticker-sym.png';
import { Tokens, useTokenContract } from '../../web3/contracts/contracts';
import { useHpayExchangePrice } from '../../web3/exchange';
import { useTargetNetwork } from '../../web3/web3';


const useIsClient = () => {
    const [isClient, setClient] = useState(false);
    const key = isClient ? "client" : "server";

    useEffect(() => {
        setClient(true);
    }, []);

    return { isClient, key };
};

const Supply = () => {
    const { supply } = useTokenContract(Tokens.HPAY.address);

    return <>
        <CountUp isCounting end={supply || 0} decimals={2} preserveValue={true} separator=',' />
    </>;
};


const Price = () => {
    const [price, setPrice] = useState(0);
    const { setTargetNetwork } = useTargetNetwork();
    const fetchRates = useHpayExchangePrice();
    useEffect(() => {
        setTargetNetwork('any');
    }, [])
    useEffect(() => {
        fetchRates(Tokens.HPAY, Tokens.BUSD).then(data => {
            return data;
        }).then(setPrice);
    }, [setPrice, fetchRates]);

    useInterval(async () => {
        const price = await fetchRates(Tokens.HPAY, Tokens.BUSD);
        setPrice(price);
    }, 10000);

    return <>
        <CountUp isCounting end={price || 0} decimals={8} preserveValue={true} separator=',' />
    </>;

};

function Tokenomics() {
    const { isClient } = useIsClient();

    return (
        <div className="py-140 pb-0" id="economics">
            <Container >
                <Row className="justify-content-center mb-5 pb-md-4 text-center">
                    <Col md={10} lg={9} className="mb-4">
                        <div className="section-title">
                            <div>Tokenomics</div>
                            <h3>Tokenomics</h3>
                        </div>
                    </Col>
                    <Col lg={9} className="px-lg-4">
                        <p>The HedgeFi Algorithm utilizes DeFi contribution to generate digital salaries<sup>1</sup> for our HPay holders to be rewarded on a monthly basis. Our HPay holders may use the rewards dashboard to claim their rewards earlier if desired. This will incur a pro-rated fee schedule that will be used to increase the resources of the HedgeFi Algorithm.</p>
                    </Col>
                </Row>

                <Row  className="justify-content-center align-items-center">
                    <Col md={6} className="mb-md-0 mb-4">
                        <Row>
                            <Col xs={6} className="mb-3">
                                <div className="eco-group">
                                    <p>Ticker Symbol</p>
                                    <h5><img src={TickerSym} alt="HedgePay Finance Ticker" /> HPAY</h5>
                                </div>
                            </Col>
                            <Col xs={6} className="mb-3 text-end text-md-start">
                                <div className="eco-group">
                                    <p>Price</p>
                                    {isClient && <h5>$<Price></Price></h5>}
                                </div>
                            </Col>
                            <Col xs={6} className="mb-3">
                                <div className="eco-group">
                                    <p>Contract Address</p>
                                    <h5>0xc75aa...dfd521a
                                        <a href="https://bscscan.com/address/0xc75aa1fa199eac5adabc832ea4522cff6dfd521a/" target="_blank" rel="noopener noreferrer"> <img src={ExpandIcon} className="ms-2 me-0" alt="icon" />
                                        </a>
                                    </h5>
                                </div>
                            </Col>
                            <Col xs={6} className="mb-3 text-end text-md-start">
                                <div className="eco-group">
                                    <p>Chain</p>
                                    <h5><img src={ChainIcon} alt="icon" /> BSC (BEP-20)</h5>
                                </div>
                            </Col>
                            <Col xs={6} className="mb-3">
                                <div className="eco-group">
                                    <p>Max Supply</p>
                                    <h5>1,000,000,000 HPAY</h5>
                                </div>
                            </Col>
                            <Col xs={6} className="mb-3 text-end text-md-start">
                                <div className="eco-group">
                                    <p>Circulating Supply</p>
                                    {isClient && <h5><Supply></Supply> HPAY</h5>}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <div className="supply-distribution">
                        <Row className='align-items-center flex-sm-row flex-column'>
                            <Col sm="5" className='mb-sm-0 mb-3 text-center'>
                                <img src={PieChart} alt="HedgePay (HPAY) distribution" className="img-fluid" />
                            </Col>
                            <Col sm="7">
                                <h6>Supply Distribution</h6>

                                <p className='d-flex mb-1 align-items-center'>
                                    <span className="pie-dot" style={{ backgroundColor: '#fff' }}></span>
                                    <span>58.56%</span>
                                    Rewards Mechanics
                                </p>

                                <p className='d-flex mb-1 align-items-center'>
                                    <span className="pie-dot" style={{ backgroundColor: '#1E4847' }}></span>
                                    <span>29.82%</span>
                                    Liquidity Provision
                                </p>

                                <p className='d-flex mb-2 align-items-center'>
                                    <span className="pie-dot" style={{ backgroundColor: '#FFE986' }}></span>
                                    <span>3.00%</span>
                                    Development*
                                </p>

                                <a target="_blank" rel="noopener noreferrer" href='https://docs.google.com/spreadsheets/d/1sbVNR2v_Es_JFXGrSNZhCtn519qlDteK4P2y7ITmDNk/edit?usp=sharing'>
                                    View
                                    <svg width="16" height="16" className='ms-2' viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.9" d="M17.5 18.6667H3.5C2.85833 18.6667 2.33333 18.1417 2.33333 17.5V3.5C2.33333 2.85833 2.85833 2.33333 3.5 2.33333H9.33333C9.975 2.33333 10.5 1.80833 10.5 1.16667C10.5 0.525 9.975 0 9.33333 0H2.33333C1.03833 0 0 1.05 0 2.33333V18.6667C0 19.95 1.05 21 2.33333 21H18.6667C19.95 21 21 19.95 21 18.6667V11.6667C21 11.025 20.475 10.5 19.8333 10.5C19.1917 10.5 18.6667 11.025 18.6667 11.6667V17.5C18.6667 18.1417 18.1417 18.6667 17.5 18.6667ZM12.8333 1.16667C12.8333 1.80833 13.3583 2.33333 14 2.33333H17.0217L6.37 12.985C5.915 13.44 5.915 14.175 6.37 14.63C6.825 15.085 7.56 15.085 8.015 14.63L18.6667 3.97833V7C18.6667 7.64167 19.1917 8.16667 19.8333 8.16667C20.475 8.16667 21 7.64167 21 7V1.16667C21 0.525 20.475 0 19.8333 0H14C13.3583 0 12.8333 0.525 12.8333 1.16667Z" fill="currentColor" />
                                    </svg>
                                </a>
                            </Col>
                        </Row>
                    </div>

                    <Col lg={8} >
                        <p className="info-sub-note mt-5">
                            * No initial development or reward tokens will be created. HedgePay will mint tokens incrementally, relative to on-chain mechanics to ensure the health of hedgepay economics.
                        </p>
                    </Col>
                </Row>

                <div className="feature-content w-100 " >
                    <Row  className="justify-content-center text-center">

                        <Col lg={9} className="px-lg-4 mb-5 mt-5">
                            <p>
                                HedgePay uses rewards generated from each transaction to increase the resources of the HedgeFi Algorithm. Every blockchain transaction that involves transferring tokens is subjected to an 4%* fee. Blockchain Transactions that involve interactions with HedgePay's swap will not incur this fee. The rewards generated will be allocated to the HedgeFi Algorithm to increase the digital salaries of our contributor.
                            </p>
                        </Col>

                        <Col md={12} className="text-center supply-distribution">
                            <img src={PieChartFee} alt="HedgePay (HPAY) distribution" className="img-fluid" />
                        </Col>

                        <Col lg={8} >
                            <p className="info-sub-note mt-5">
                                * Exit transactions are subjected to a 4% fee.
                                <br />
                                * On-Chain transactions that do not interact with the HedgePay system are subjected to a 4% fee.
                            </p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
}

export default Tokenomics;
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function roadmap() {
    return (
        <div className="py-140 pb-0">
            <Container id="roadmap">
                <Row className="justify-content-center mb-4  text-center">
                    <Col lg={12}>
                        <div className="section-title text-center">
                            <div>Roadmap</div>
                            <h3>Roadmap</h3>
                        </div>
                    </Col>

                    <Col lg={9} className="px-lg-4">
                        <p>
                        HedgePay is the centre of an ecosystem that overcomes traditional barriers between savings and liquidity. Spend your BUSD rewards on your daily needs while keeping your tokens safe and increasing in value
                        </p>
                    </Col>
                </Row>

                <div className="feature-list">
                    <div className="feature-row d-md-flex justify-content-md-end">
                        <div className="feature-item">
                            <h4>Foundation - Phase 1</h4>
                            <ul>
                                <li>Swap with Swap Orders</li>
                                <li>Implementation of Reward Mechanics</li>
                                <li>Buyback-Powered Staking Protocol</li>
                                <li>Expanding Types of Pool and Increment of Annual Percentage Yield</li>
                                <li>Incorporation and Trademark</li>
                                <li>Public Relation Department</li>
                            </ul>
                        </div>
                    </div>
                    <div className="feature-row d-md-flex">
                        <div className="feature-item">
                            <h4>Accessibility - Phase 2</h4>
                            <ul>
                                <li>HedgePay DApp Wallet UI</li>
                                <li>HedgePay Fiat Bridge</li>
                                <li>Cross-Chain Integration</li>
                                <li>International Website Upgrade</li>
                            </ul>
                        </div>
                    </div>
                    <div className="feature-row d-md-flex justify-content-md-end">
                        <div className="feature-item">
                            <h4>Expansion - Phase 3</h4>
                            <ul>
                                <li>Swapping</li>
                                <li>AI-Guided Customization</li>
                                <li>HedgePay Card</li>
                                <li>Branding</li>
                                <li>Payment Processing Services</li>
                            </ul>
                        </div>
                    </div>
                    <div className="feature-row d-md-flex">
                        <div className="feature-item">
                            <h4>Mainstream - Stage 4</h4>
                            <ul>
                                <li>Influencer Platform</li>
                                <li>Mobile Applications</li>
                                <li>Cross Chain Staking and Launchpad</li>
                                <li>Automated Launchpad Implementation</li>
                                <li>Auto Compounding Vault</li>
                                <li>Peer to Peer Platform</li>
                                <li>HedgePay Business Management System</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default roadmap;
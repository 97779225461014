import React from 'react';
import ScrollUpButton from "react-scroll-up-button";
// componnets
import Header from '../components/header';
import About from '../components/landing/about';
import Partnerships from '../components/landing/partnershipsNew';
import Features from '../components/landing/features';
import Tokenomics from '../components/landing/tokenomics';
import UpcomingFeatures from '../components/landing/upcomingFeatures';
import Layout from "../components/layout";
import Seo from "../components/seo";
// import GivingBlock from '../components/landing/givingBlock';


const landing = () => {
    return (
        <Layout pageInfo={{ pageName: "index" }}>
            <ScrollUpButton ContainerClassName="custom-scroll-button" />
            <Seo title="DeFi Powered Rewards Generator" />
            <Header id="header" />
            <Features />
            <Tokenomics />
            <UpcomingFeatures />
            <About />
            {/* <GivingBlock /> */}
            <Partnerships />
        </Layout>
    );
};

export default landing;